'use client'

import jwtDecode from 'jwt-decode'
import { GOOGLE_CLIENT_ID } from '@premeeting/lib/constants'
import { GoogleCredentialType } from '@premeeting/lib/types'
import Script from 'next/script'
import { createRef, useCallback, useEffect, useState } from 'react'
import cn from 'classnames'

export default function GoogleSignIn({
  onSignIn,
}: {
  onSignIn: (token: string, email: string) => void
}) {
  const googleButtonRef = createRef<HTMLDivElement>()
  const [googleScriptLoaded, setGoogleScriptLoaded] = useState(false)

  const handleCredentialResponse = useCallback(
    (response: { clientId: string; credential: string; select_by: string }) => {
      const decoded = jwtDecode<GoogleCredentialType>(response.credential)
      onSignIn(response.credential, decoded.email)
    },
    [onSignIn],
  )

  useEffect(() => {
    if (googleButtonRef && googleScriptLoaded) {
      window.google.accounts.id.initialize({
        client_id: GOOGLE_CLIENT_ID,
        callback: handleCredentialResponse,
      })

      window.google?.accounts.id.renderButton(googleButtonRef.current, {
        theme: 'outline',
        size: 'large',
      })
    }
  }, [googleButtonRef.current, googleScriptLoaded])

  // @ts-ignore
  if (
    typeof window != 'undefined' &&
    window.location.host.includes('crystl.app')
  ) {
    return (
      <button type="button" onClick={() => onSignIn('', '')}>
        Login with Google
      </button>
    )
  }

  return (
    <div className={cn('tw-mt-0')}>
      <Script
        src="https://accounts.google.com/gsi/client"
        onLoad={() => {
          setGoogleScriptLoaded(true)
        }}
      />

      <div ref={googleButtonRef} />
    </div>
  )
}
